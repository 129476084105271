/*
ssg
缴社保缴公积金流程 存储信息
*/
const state = {
    info:'',
}
const getters = {
  getOrder: state => {
    if(state.info == '') {
      let tempInfo = localStorage.getItem("rsgjorder")
      if(tempInfo)
        state.info = JSON.parse(tempInfo)
    }
    return state.info
  }
}
const mutations = {
  setOrderInfo (state, info) {
    state.info = info
    localStorage.setItem('rsgjorder', JSON.stringify(state.info))
  },
  clearOrderInfo (state) {
    state.info = ''
    localStorage.removeItem('rsgjorder');
  },

}
export default {
  state,
  getters,
  mutations,
}