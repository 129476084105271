<!-- eslint-disable -->
<template>
  <div class="cityselected" ref="citySelect" v-if="isShow" :style="{paddingTop: $statusBarHeight + 'px' }">
    <div class="search" ref="citySelect" :style="{paddingTop: $statusBarHeight + 'px' }">
      <span class="search-back" @click="isShow = false">
        <img src="@/assets/back-black.png" class="back-icon" />
      </span>
      <input type="text" placeholder="请输入首字母或城市名称" v-model="search" />
    </div>
    <div class="info">
      <div class="content">
        <div v-if="!search">
          <div>
            <div class="name">
              <p>您当前位置</p>
            </div>
          </div>
          <div class="city">
            <span @click="isShow = false">{{userInfo.cityname?userInfo.cityname:"未定位到当前城市"}}</span>
          </div>
        </div>
        <!--城市首字母查询-->
        <div
          class="citylist"
          v-show="!(/^[\u3220-\uFA29]+$/.test(search))"
          v-for="item in cityFilter"
          :key="item.name"
        >
          <div>
            <span :id="item.name">{{item.name}}</span>
          </div>
          <ul>
            <li v-for="(itemone,index) in item.sjgjRegions" :key="index+'a'">
              <p @click="selectCity(itemone)">{{itemone.regionName}}</p>
            </li>
          </ul>
        </div>
        <!--城市名称查询-->
        <div
          class="citylist"
          v-show="(/^[\u3220-\uFA29]+$/.test(search))"
          v-for="(itemone,index) in cityFilter"
          :key="index"
        >
          <ul>
            <li>
              <p @click="selectCity(itemone)">{{itemone.regionName}}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="contentright" v-show="!(/^[\u3220-\uFA29]+$/.test(search))"  :style="{top: $statusBarHeight+80 + 'px' }">
        <ul>
          <li v-for="itemright in cityFilter" :key="itemright.name+'s'">
            <p @click="rightCity(itemright.name)">{{itemright.name}}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
export default {
  name: "v-city-select",
  data() {
    return {
      isShow: true,
      message: "",
      cityData: [],
      selectCityData: [],
      test: false,
      search: "",
      locationCityid: ""
    };
  },
  mounted() {
    this.locationCityid = this.userInfo.cityid;
    //获取热门城市
    this.getCityList();
  },
  computed: {
    userInfo() {
      return store.getters.getUserInfo;
    },
    cityFilter() {
      // 城市搜索筛选
      if (/^[\u3220-\uFA29]+$/.test(this.search)) {
        let newArr = this.selectCityData
          .filter(item => item.regionName.indexOf(this.search) != -1)
          .map(item => item);
        return newArr;
      } else {
        let newArr = this.cityData
          .filter(
            item =>
              item.name.toLowerCase().indexOf(this.search.toLowerCase()) != -1
          )
          .map(item => item);
        return newArr;
      }
    }
  },
  methods: {
    //获得城市信息
    getCityList() {
      this.$axios.get("/homepage/getCity").then(response => {
        if (response) {
          for (let a = 0; a < response.data.data.length; a++) {
            if (response.data.data[a].sjgjRegions.length == 0) {
              response.data.data.splice(a, 1);
            }
            this.selectCityData = this.selectCityData.concat(
              response.data.data[a].sjgjRegions
            );
          }
          response.data.data.splice(18, 1);
          this.cityData = response.data.data;
        }
      });
    },
    //选中的城市
    selectCity(city) {
      if (!city.regionName || !city.id) {
        return;
      }
      this.message.callback(city.id, city.regionName);
      this.isShow = false;
    },
    //点击城市首字母跳入对应城市
    rightCity(id) {
      this.$refs.citySelect.scrollTo(0, document.getElementById(id).offsetTop);
    }
  }
};
</script>

<style lang='scss' scoped>
.cityselected {
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 750vw;
  height: 100vh;
  z-index: 99999;
  overflow-y: auto;
  .search {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background: white;
    box-shadow: 0 0 5px #ccc;
    padding-bottom: 20px;
    .search-back {
      padding: 28px 15px 15px 28px;
      text-align: center;
      .back-icon {
        margin-bottom: -8px;
        width: 19px;
      }
    }
    input {
      margin-top: 20px;
      margin-left: 30px;
      margin-right: 10px;
      padding-left: 20px;
      width: 600px;
      height: 70px;
      background: #f2f2f2;
      border: none;
      border-radius: 10px;
    }
  }
  .info {
    padding-top: 50px;
    width: 100%;
    margin: 0 auto;
    .content {
      background: white;
      margin-top: 90px;
      .position {
        float: left;
        margin-left: 30px;
        img {
          width: 27px;
          height: 34px;
        }
      }
      .name {
        margin-left: 30px;
        margin-bottom: 10px;
        color: #999999;
      }
      .city {
        span {
          margin-left: 30px;
          background: #2478ff;
          border-radius: 20px;
          padding: 5px 20px 5px 20px;
          color: #ffffff;
          font-size: 26px;
        }
      }
      .citylist {
        margin-top: 20px;
        margin-left: 30px;
        margin-right: 70px;
        ul {
          li {
            height: 80px;
            line-height: 80px;
            margin-bottom: 10px;
            border-bottom: 1px solid #e8ecf1;
          }
        }
      }
    }
    .contentright {
      position: fixed;
      top: 160px;
      right: 27px;
      background: #cccccc;
      color: #ffffff;
      width: 40px;
      text-align: center;
      border-radius: 10px;
      p {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
}
</style>