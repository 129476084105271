<template>
  <div class="s-radio">
    <div class="radio-type" :class="msg == value ? 'radio-type-active' : ''" @click="check">
      <span class="radioyl"></span>
      <span class="radioyr"></span>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "v-radio",
  model: {
    prop: "msg",
    event: "submit"
  },
  props: {
    msg: {},
    disableds:false,
    value: ""
  },
  data() {
    return {
      myMsg: false
    };
  },
  mounted() {
    this.myMsg = this.msg;
  },
  methods: {
    check() {
      if(this.disableds) return
      this.$emit("submit", this.value);
      this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss" scoped>
.s-radio {
  display: inline;
  padding: 0 18px;
  position: relative;
  .radio-type {
    position: absolute;
    border-radius: 50%;
    display: block;
    top: 5px;
    left: 5px;
    width: 25px;
    height: 25px;
    border: 1px solid #999;
    border-radius: 50%;
  }
  .radio-type-active {
    border: 1px solid #2e7bfe;
    background: #2e7bfe;
    .radioyl {
      position: absolute;
      display: block;
      margin-top: 15px;
      margin-left: 4px;
      width: 8px;
      height: 2px;
      transform: rotate(45deg);
      background: white;
    }
    .radioyr {
      position: absolute;
      display: block;
      margin-top: 13px;
      margin-left: 8px;
      width: 15px;
      height: 2px;
      transform: rotate(-45deg);
      background: white;
    }
  }
}
</style>
