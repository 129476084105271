<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
export default {
    mounted() {
        window.myHistory = () => {
            var path = this.$route.path
            if (path == '/index/index' || 
                path == '/classify/list' ||
                path == '/mine/service' ||
                path == '/information/list' ||
                path == '/mine/index'           
            ) {
                // 到达主页面, 无法后退只能退出
                /* eslint-disable */
                uni.postMessage({
                    data: {
                        message: '我要退出!',
                        type: 'outApp'
                    }
                })
            } else {
                if (this.$route.path.indexOf('/creatorder/orderdetail') > -1) {
                    this.$router.replace('/creatorder/orderlist')
                } else if (this.$route.path.indexOf('/creatorder/orderlist') > -1) {
                    this.$router.replace('/mine/index')
                } else {
                    this.$router.go(-1)
                }
            }
        }
    }
}
</script>
