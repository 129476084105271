/*
ssg
用户信息,权限处理
*/
const state = {
    userInfo: {
        id: '',
    }
}
const getters = {
    getUserInfo: state => {
        let tempInfo = localStorage.getItem("rsgjUserInfo")
        if (tempInfo)
            state.userInfo = JSON.parse(tempInfo)
        return state.userInfo
    }
}
const mutations = {
    setMyUserInfo(state, userInfo) {
        state.userInfo = userInfo
        localStorage.setItem('rsgjUserInfo', JSON.stringify(userInfo))
    }
}
const actions = {
    setUserInfo({ commit }, userInfo) {
        commit('setMyUserInfo', { userInfo })
    }
}
export default {
    state,
    getters,
    mutations,
    actions
}