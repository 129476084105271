import axios from 'axios'
if (process.env.NODE_ENV == 'development') {
    // axios.defaults.baseURL = 'http://192.168.1.47:8002/rsgjweb/'
    // axios.defaults.baseURL = 'http://192.168.1.238:8002/rsgjweb/'
    axios.defaults.baseURL = 'http://dgj.renshewuyou.com/rsgjweb/'
} else if (process.env.NODE_ENV === 'test') {
    axios.defaults.baseURL = 'http://192.168.1.47:8002/rsgjweb/'
} else if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = 'http://dgj.renshewuyou.com/rsgjweb/'
        // axios.defaults.baseURL = 'http://dadmin.dangguanjia.cn/rsgjweb/'
}


export default axios