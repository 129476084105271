<template>
  <transition name="fade">
    <div class="month-select" v-if="isShow">
      <div class="s-tips-tips">
        <img @click="close()" class="close-img pull-right" src="@/assets/close.png" />
        <div class="my-title">请选择缴纳月份</div>
        <ul class="select-months">
          <li @click="setovermonth(0)">
            <div class="select-month">
              <v-radio v-model="overmonth" :value="1"></v-radio>1个月(￥
              <span class="select-red">{{options.businessCharge[0].shoufei}}</span>元)
            </div>
            <div class="select-tips">推荐临时换工作,临时缴纳人群</div>
          </li>
          <li @click="setovermonth(1)">
            <div class="select-month" :class="{'select-month-disabled':maxMonth<3}">
              <v-radio v-model="overmonth" :disableds="maxMonth<3" :value="3"></v-radio>3个月(￥
              <span class="select-red">{{options.businessCharge[1].shoufei}}</span>元)
            </div>
            <div class="select-tips">推荐短期过渡,临时特殊清空的人群</div>
          </li>
          <li @click="setovermonth(2)">
            <div class="select-month" :class="{'select-month-disabled':maxMonth<6}">
              <v-radio v-model="overmonth" :disableds="maxMonth<6" :value="6"></v-radio>6个月(￥
              <span class="select-red">{{options.businessCharge[2].shoufei}}</span>元)
            </div>
            <div class="select-tips">推荐需要医疗业务等配套的需求人群</div>
          </li>
          <li @click="setovermonth(3)">
            <div class="select-month" :class="{'select-month-disabled':maxMonth<12}">
              <v-radio v-model="overmonth" :disableds="maxMonth<12" :value="12"></v-radio>12个月(￥
              <span class="select-red">{{options.businessCharge[3].shoufei}}</span>元)
            </div>
            <div class="select-tips">推荐个体工商，自由工作者此类人群</div>
          </li>
        </ul>
        <div class="con-bottom" @click="submit()">确定</div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "dnw-tips",
  data() {
    return {
      options: "",
      overmonth: 1, //值
      lable: "", //标题
      maxMonth: 12,
      isShow: true
    };
  },
  mounted() {
    this.getMaxMonth();
    this.setovermonth(0);
  },
  methods: {
    /**
     * 获取最大缴费月
     */
    getMaxMonth() {
      this.maxMonth = this.options.maxMonth;
    },
    /**
     * 选择月份
     */
    setovermonth(index) {
      if (this.maxMonth < 12 && index > 2) return;
      if (this.maxMonth < 6 && index > 1) return;
      if (this.maxMonth < 3 && index > 0) return;
      let element = this.options.businessCharge[index];
      this.overmonth = element.taocan;
      this.lable = `${element.taocan}个月(￥${element.shoufei}元)`;
      this.shoufei = element.shoufei;
    },
    /**
     * 点击确定回调
     */
    submit() {
      if (this.options.hasOwnProperty("callback")) {
        this.options.callback({
          overmonth: this.overmonth,
          lable: this.lable,
          shoufei: this.shoufei
        });
      }
      this.isShow = false;
    },
    close() {
      this.isShow = false;
    }
  }
};
</script>

<style lang="scss" scope>
.month-select {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index: 9999999;
  .s-tips-tips {
    padding: 30px 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 530px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 8px;
    font-size: 18px;
    overflow: hidden;
    .close-img {
      width: 26px;
    }
    .my-title {
      font-size: 34px;
      color: #333;
      text-align: center;
    }
    .select-months {
      font-size: 26px;
      text-align: left;
      padding: 30px 10px;
      li {
        padding: 15px 30px;
        border-bottom: 1px solid #ccc;
        &:last-child {
          border: 0;
        }
        .select-month {
          color: #666;
          font-size: 28px;
          font-weight: 600;
          .select-red {
            color: red;
          }
          .s-radio {
            margin-left: -30px;
            margin-right: 10px;
          }
          &.select-month-disabled {
            color: #ccc;
            .select-red {
              color: #ccc;
            }
          }
        }
        .select-tips {
          margin-top: 10px;
          font-size: 24px;
          text-indent: 12px;
          color: #999;
        }
      }
    }
    .con-bottom {
      display: block;
      margin: 0 auto;
      width: 340px;
      height: 80px;
      line-height: 80px;
      font-size: 32px;
      color: white;
      background: #1a55dd;
      border-radius: 10px;
      text-align: center;
    }
  }
}
</style>
