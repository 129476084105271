<!-- eslint-disable -->
<template>
  <div class="school-selected" v-if="isShow"  :style="{paddingTop: $statusBarHeight + 'px' }">
    <div class="search" :style="{paddingTop: $statusBarHeight + 'px' }">
      <span class="search-back" @click="isShow = false">
        <img src="@/assets/back-black.png" class="back-icon" />
      </span>
      <input type="text" placeholder="请输入首字母或学校名称" v-model="search" />
    </div>
    <div class="info">
      <div class="content">
        <!--城市名称查询-->
        <div class="citylist" v-for="item in cityFilter" :key="item.id">
          <ul>
            <li>
              <p @click="selectCity(item)">{{item.name}}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
export default {
  name: "v-school-select",
  data() {
    return {
      isShow: true,
      message: "",
      allCity: [], // 热门城市
      schoolList: [],
      test: false,
      search: ""
    };
  },
  watch: {
cityFilter() {
  console.log(123)
  this.$forceUpdate()
}
  },
  mounted() {
    this.getSchool();
  },
  computed: {
    userInfo() {
      return store.getters.getUserInfo;
    },
    cityFilter() {
      console.log(this.search)
      let cc = this.schoolList
        .filter(item => item.name.indexOf(this.search) != -1)
      console.log(cc)
      // 城市搜索筛选
      return this.schoolList
        .filter(item => item.name.indexOf(this.search) != -1)
    }
  },
  methods: {
    /**
     * 获取毕业院校
     */
    getSchool() {
      this.$siaxios.get("/common/allSchool").then(res => {
        this.schoolList = res;
      });
    },
    //选中的城市
    selectCity(city) {
      if (!city.name || !city.id) {
        return;
      }
      this.message.callback(city.id, city.name);
      this.isShow = false;
    }
  }
};
</script>

<style lang='scss' scoped>
.school-selected {
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 750vw;
  height: 100vh;
  z-index: 99999;
  overflow-y: auto;
  .search {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background: white;
    box-shadow: 0 0 5px #ccc;
    padding-bottom: 20px;
    .search-back {
      padding: 28px 15px 15px 28px;
      text-align: center;
      .back-icon {
        margin-bottom: -8px;
        width: 19px;
      }
    }
    input {
      margin-top: 20px;
      margin-left: 30px;
      margin-right: 10px;
      padding-left: 20px;
      width: 600px;
      height: 70px;
      background: #f2f2f2;
      border: none;
      border-radius: 10px;
    }
  }
  .info {
    width: 100%;
    margin: 0 auto;
    .content {
      margin-top: 120px;
      background: white;
      .citylist {
        margin-top: 20px;
        margin-left: 30px;
        margin-right: 70px;
        ul {
          li {
            height: 80px;
            line-height: 80px;
            margin-bottom: 10px;
            border-bottom: 1px solid #e8ecf1;
          }
        }
      }
    }
    .contentright {
      position: fixed;
      top: 160px;
      right: 27px;
      background: #cccccc;
      color: #ffffff;
      width: 40px;
      text-align: center;
      border-radius: 10px;
      p {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
}
</style>