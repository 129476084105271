/*

选择提示框

*/


import Vue from 'vue'
import cityselect from './city'

cityselect.installConfirm = function(options) {
  if (options === undefined || options === null) {
    options = {
      message: ''
    }
  } else if (typeof options === 'string' || typeof options === 'number') {
    options = {
      message: options
    }
  } else {
    options = {
      message: options
    }
  }
  const message = Vue.extend(cityselect)
  const component = new message({
    data: options
  }).$mount()
  document.getElementById('app-main').getElementsByTagName('div')[0].appendChild(component.$el)
}

export default cityselect