import radio from './radio' //单选纽
import tips from './tips' //提示框
import alert from './alert' //提示框
import confirm from './confirm' //提示框
import submit_success from './submit-success' //提示框
import consult from './consult' //咨询页面
import money from './money' //缴费续费
import grallery from './grallery' //缴费续费
import pay from './pay/pay' //支付模块
import loading from './load' //加载框
import cityselect from './city-select' //城市选择
import timeselect from './time-select' //时间选择
import cityopenselect from './city-open-select' //开通服务城市选择
import citycalculatorselect from './city-calculator-select' //开通计算器城市选择
import monthselect from './month-select' //社保公积金月份选择
import schoolselect from './school-select' //选择学校

const install = function(Vue) {

    Vue.component(loading.name, loading)
    Vue.component(radio.name, radio)
    Vue.component(timeselect.name, timeselect)
    Vue.component(pay.name, pay)
    Vue.component(money.name, money)
    Vue.prototype.$money = money.installTips

    Vue.component(grallery.name, grallery)
    Vue.prototype.$grallery = grallery.installTips

    Vue.component(tips.name, tips)
    Vue.prototype.$tips = tips.installTips
    Vue.component(alert.name, alert)
    Vue.prototype.$alert = alert.installAlert
    Vue.component(confirm.name, confirm)
    Vue.prototype.$confirm = confirm.installConfirm
    Vue.component(submit_success.name, submit_success)
    Vue.prototype.$submitSuccess = submit_success.installSubmit
    Vue.component(cityselect.name, cityselect)
    Vue.prototype.$cityselect = cityselect.installConfirm
    Vue.component(monthselect.name, monthselect)
    Vue.prototype.$monthselect = monthselect.installMonth
    Vue.component(cityopenselect.name, cityopenselect)
    Vue.prototype.$cityopenselect = cityopenselect.installConfirm
    Vue.component(citycalculatorselect.name, citycalculatorselect)
    Vue.prototype.$citycalculatorselect = citycalculatorselect.installConfirm
    Vue.component(consult.name, consult)
    Vue.prototype.$consult = consult.installConsule
    Vue.component(schoolselect.name, schoolselect)
    Vue.prototype.$schoolselect = schoolselect.installSchool
}

export default install