import Vue from 'vue'
import Router from 'vue-router'
import routes from './routers'
import store from '../store'
// import confirm from '@/components/plug-in/confirm'
import wxSdk from 'weixin-js-sdk'

Vue.use(Router)
const router = new Router({
    // mode: 'history',
    routes,
})
let isforward = false
router.forward = (param) => {
    isforward = true
    router.push(param)
}

router.beforeEach((to, from, next) => {
    if (from.meta.keepAlive) {
        from.meta.savedPosition = document.getElementById("app-main").scrollTop;
    }
    //隐藏分享按钮
    wxSdk.ready(function() {
            wxSdk.hideAllNonBaseMenuItem()
        })
        //如果时登录页往后退到我的页面 则直接跳转首页
    if (from.path == '/login/login' && to.path == '/mine/set' && store.getters.getUserInfo.id == "") {
        router.replace("/index/index")
    }
    //功能使用需要登录
    let temppath = to.path.split('/')[1]
    if ((temppath == 'mine' || temppath == 'order' || temppath == 'graduate') && store.getters.getUserInfo.id == "") {
        router.forward("/login/login");
    }
    //5个首页不能后退
    else if ((from.path == '/mine/index' || from.path == '/information/list' || from.path == '/mine/service' || from.path == '/classify/list' || from.path == '/index/index') && !isforward) {
        next(false)
    } else if (isforward && from.path == '/mine/backarchives' && to.path == '/mine/archives') {
        if (to.meta.title) {
            document.title = to.meta.title //设置网页title
        }
        next({ path: '/mine/business' })
    } else {
        if (to.meta.title) {
            document.title = to.meta.title //设置网页title
        }
        next()
    }
    //如果时前进则重置滚动条到最顶部
    if (isforward) to.meta.savedPosition = 0
    isforward = false
})

router.afterEach((to) => {
    let app_main = document.getElementById("app-main")
    if (to.meta.keepAlive) {
        if (app_main) {
            setTimeout(() => {
                app_main.scrollTo(0, to.meta.savedPosition);
            }, 50);
        }
    } else {
        if (app_main) {
            app_main.scrollTo(0, 0);
        }

    }
})

export default router