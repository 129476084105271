<template>
    <div class="poput">
        <transition name="fade">
            <div class="poput-main">
                <div class="title">分享到</div>
                <div class="share-type">
                    <div class="item" v-for="(item,index) in bottomData" :key="index" @click="select(index)">
                        <img :src="item.icon" mode="aspectFill" /><br>
                        <span>{{item.text}}</span>
                    </div>
                </div>
                <button class="uni-share-button" @click="$emit('close')">取消</button>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    props:{
        data:Object
    },
    data() {
        return {
            isShow: true,
            bottomData: [{
                text: '微信',
                icon: require('@/assets/weixin.png'),
                name: 'wx'
            },
            {
                text: '朋友圈',
                icon: require('@/assets/friendcricle.png'),
                name: 'fc'
            }
            ]
        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        select(index) {
            if (index == 0) {
                // eslint-disable-next-line
                uni.postMessage({
                    data: {
                        message: this.data,
                        type: 'shareWeixin'
                    }
                })
            } else {
                // eslint-disable-next-line
                uni.postMessage({
                    data: {
                        message: this.data,
                        type: 'shareFriendcricle'
                    }
                })
            }
            this.$emit('close')
        }
    },
}
</script>
<style lang="scss" scoped>
.fade-enter-active {
    animation: fade-in 0.3s ease-out;
}
.fade-leave-active {
    animation: fade-in 0.3s reverse ease-out;
}
@keyframes fade-in {
    0% {
        top: -40px;
    }
    100% {
        top: 100px;
    }
}
.poput {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 9999;
    .poput-main {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: #fff;
        display: flex;
        flex-direction: column;
        .title {
            font-size: 24px;
            line-height: 50px;
            text-align: center;
            color: #666;
            margin-top: 30px;
        }
        .share-type {
            display: flex;
            justify-content: center;
            padding: 60px 0;
            .item {
                width: 200px;
                margin: 0 20px;
                text-align: center;
                img {
                    width: 90px;
                    margin-bottom: 15px;
                }
                span {
                    font-size: 28px;
                }
            }
        }
        button {
            background: none;
            height: 100px;
            font-size: 26px;
            border: none;
            border-top: #eee 1px solid;
        }
    }
}
</style>
