<template>
    <div class="ns-nav">
        <div class="item" ref="navContainer" v-for="(item,$index) in navCon" :key="$index" @click="changeType(item.path)">
            <img :src="getServiceFace(item,$index)" class="icon" :class="{'service':$index==2}" />
            <p :class="{'blue':item.active}">{{item.name}}</p>
        </div>
    </div>
</template>

<script>
import home from "@/assets/layout/home.png";
import home_select from "@/assets/layout/home-select.png";
import service from "@/assets/layout/service.png";
import service_select from "@/assets/layout/service-select.png";
import find from "@/assets/layout/find.png";
import find_select from "@/assets/layout/find-select.png";
import mine from "@/assets/layout/mine.png";
import mine_select from "@/assets/layout/mine-select.png";
import { mapGetters } from "vuex";

import csd from "@/assets/layout/csd.png";

export default {
    name: "ns-nav",
    data() {
        return {
            navCon: [
                {
                    name: "首页",
                    active: true,
                    icon: home,
                    active_icon: home_select,
                    path: "/index/index"
                },
                {
                    name: "服务",
                    active: false,
                    icon: service,
                    active_icon: service_select,
                    path: "/classify/list"
                },
                {
                    name: "顾问",
                    active: false,
                    icon: csd,
                    active_icon: csd,
                    path: "/mine/service"
                },
                {
                    name: "发现",
                    active: false,
                    icon: find,
                    active_icon: find_select,
                    path: "/information/list"
                },
                {
                    name: "我的",
                    active: false,
                    icon: mine,
                    active_icon: mine_select,
                    path: "/mine/index"
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            userInfo: "getUserInfo"
        })
    },
    watch: {
        $route() {
            this.setNavActive();
        }
    },
    mounted() {
        this.setNavActive();
    },
    methods: {
        getServiceFace(item, index) {
            if (index == 2 && this.userInfo.salemenheadurl) {
                return this.userInfo.salemenheadurl;
            }
            return item.active ? item.active_icon : item.icon;
        },
        /**
         * 设置底部导航焦点位置
         */
        setNavActive() {
            this.navCon.forEach(element => {
                if (element.path == this.$route.path) {
                    element.active = true;
                } else {
                    element.active = false;
                }
            });
        },
        /**
         * 点击导航跳转路由位置并修改当前状态
         */
        changeType(path) {
            this.$router.forward(path);
        }
    }
};
</script>

<style lang="scss" scoped>
.ns-nav {
    position: fixed;
    width: 100%;
    height: 100px;
    bottom: 0;
    left: 0;
    background: white;
    box-shadow: 0 -5px 5px rgba($color: #000, $alpha: 0.05);
    z-index: 9;
    .item {
        float: left;
        margin-top: 8px;
        width: 20%;
        height: 106px;
        text-align: center;
        border-radius: 15px 15px 0 0;
        .icon {
            margin-top: 6px;
            width: 40px;
        }
        .service {
            margin-top: -36px;
            border: 8px solid white;
            box-shadow: 0 -5px 5px rgba($color: #000, $alpha: 0.05);
            border-radius: 50%;
            width: 67px;
            height: 67px;
        }
        p {
            font-size: 22px;
            color: #a3adae;
            &.blue {
                color: #3889ff;
            }
        }
    }
}
</style>


