<template>
  <div class="v-time-select">
    <div class="time-container" ref="timeSelect">{{showInfo==''?'请选择':showInfo}}</div>
  </div>
</template>

<script>
import MobileSelect from "@/components/plug-in/mobile-select";
/* eslint-disable */
export default {
  name: "time-select",
  model: {
    prop: "msg",
    event: "submit"
  },
  props: {
    msg: {},
    value: ""
  },
  data() {
    return {
      showInfo:'',
      datatimeList: [
        //年
        { data: [] },
        //月
        {
          data: [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12"
          ]
        },
        //日
        {
          data: [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31"
          ]
        }
      ]
    };
  },
  mounted() {
    this.getNowData()
    this.iniSelect();
  },
  methods: {
    /**
     * 获取当前时间
     */
    getNowData() {
      var dayNow = new Date();
      this.showInfo =  dayNow.getFullYear()+"-" + (dayNow.getMonth()+1) + "-" + dayNow.getDate();
      this.$emit("submit", this.showInfo);
    },
    /**
     * 初始化下拉列表
     */
    iniSelect() {
      //初始化年
      let currentYear = new Date().getFullYear();
      for (let index = currentYear; index > currentYear - 130; index--) {
        this.datatimeList[0].data.push(index);
      }
      let birthdayMSelect = new MobileSelect({
        trigger: this.$refs.timeSelect,
        title: "日期选择",
        triggerDisplayData: false,
        wheels: this.datatimeList,
        transitionEnd: (indexArr, data) => {
          //根据年月设置天数
          var thisDate = new Date(data[0], data[1], 0).getDate();
          let tempList = [];
          for (let index = 1; index <= thisDate; index++) {
            tempList.push((Array(2).join(0) + index).slice(-2));
          }
          birthdayMSelect.updateWheel(2, tempList);
        },
        callback: (indexArr, data) => {
          this.showInfo = data[0] + "-" + data[1] + "-" + data[2]
          this.$emit("submit", this.showInfo);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.v-time-select {
  width: 300px;
  .time-container {
    width: 100%;
    height: 100%;
  }
}
</style>
