<template>
    <div id="app-con">
        <div class="top-nav-copy" :style="{paddingTop: $statusBarHeight + 'px' }" v-if="showTop"></div>
        <top-nav v-if="showTop"></top-nav>
        <div class="app-main" id="app-main">
            <keep-alive :include="includedRouter">
                <router-view />
            </keep-alive>
        </div>
        <us-nav v-if="$route.meta.showBottom"></us-nav>
        <div class="ns-nav-copy" v-if="$route.meta.showBottom"></div>
    </div>
</template>

<script>
import topnav from "./titlebar";
import usnav from "./navbar";
import { mapGetters } from "vuex";
import router from "@/router/routers";
export default {
    name: "layout",

    components: {
        "top-nav": topnav,
        "us-nav": usnav
    },
    computed: {
        ...mapGetters({
            userInfo: "getUserInfo"
        }),
        includedRouter() {

            return router[0].children
                .filter(x => !(x.meta && !x.meta.keepAlive))
                .map(x => {
                    return x.name;
                });

        },
        showTop() {
            //在app内才展示头部
            if (
                this.$route.path == "/mine/index" ||
                this.$route.path == "/information/list" ||
                this.$route.path == "/index/index" ||
                this.$route.path == "/mine/service" 
                // this.$route.path == "/login/login"
            ) {
                /* eslint-disable */
                if (this.$isPlus) {
                    plus.navigator.setStatusBarStyle("light");
                }
                return false;
            } else {
                if (this.$isPlus) {
                    /* eslint-disable */
                    plus.navigator.setStatusBarStyle("dark");
                }
                //默认是显示头部
                if (this.$isPlus && this.$route.meta.showTop == undefined) { return true; }
                return false;
            }

        },
        isSetMinHeight() {
            return this.showTop && this.$route.meta.showBottom;
        }
    }
};
</script>
<style lang="scss">
.ns-nav-copy {
    height: 100px;
}
.top-nav-copy {
    height: 90px;
}
#app-con {
    height: 50%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    .app-main {
        flex: 1;
        overflow-y: auto;
        width: 100%;
        position: relative;
        justify-content: flex-start;
        align-content: flex-start;
    }
}
</style>
