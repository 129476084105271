<template>
  <div class="pop-loading" :style="{'top': top, 'left': left, 'width': width, 'height': height}">
    <div class="bg"></div>
    <div class="pop-container">
      <svg viewBox="25 25 50 50" class="circular"><circle cx="50" cy="50" r="20" fill="none" class="path"></circle></svg>
    </div>
  </div>
</template>

<script type="text/javascript">
  export default {
    name: 'plug-loading',
    data () {
      return {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pop-loading {
    color: #45B0FF;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #fff;
      opacity: 0.7;
      z-index: 999;
    }
    .pop-container {
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -100%);
      height: 100px;
      width: 100px;
      z-index: 9999999999;
      .circular {
        height: 100%;
        width: 100%;
        animation: loading-rotate 2s linear infinite;
      }
      .path {
        animation: loading-dash 1.5s ease-in-out infinite;
        stroke-dasharray: 90,150;
        stroke-dashoffset: 0;
        stroke-width: 2;
        stroke: #409eff;
        stroke-linecap: round;
      }
    }
  }
  @keyframes loading-rotate {
    100% {
      transform: rotate(1turn);
    }
  }
  @keyframes loading-dash {
    0% {
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90,150;
        stroke-dashoffset: -40px;
    }
    100% {
        stroke-dasharray: 90,150;
        stroke-dashoffset: -120px;
    }
  }
</style>
