/*
计算器存储
*/
const state = {
    calculator: {}
}
const getters = {
    getCalculator: state => {
        let tempInfo = localStorage.getItem("calculator")
        if (tempInfo)
            state.calculator = JSON.parse(tempInfo)
        return state.calculator
    }
}
const mutations = {
    setCalculator(state, info) {
        state.calculator = info
        localStorage.setItem('calculator', JSON.stringify(info))
    }
}
const actions = {
    setMyCalculator({ commit }, info) {
        commit('setCalculator', { info })
    }
}
export default {
    state,
    getters,
    mutations,
    actions
}