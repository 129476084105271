<template>
    <div class="com-consult-index" v-if="isShow">
        <div class="consult-win">
            <div class="consult-title">您的专属顾问</div>
            <div class="consult-name">{{userInfo.salemennickname}}</div>
            <img @click="close()" class="consult-close" src="@/assets/close.png" />
            <img :src="userInfo.salemencodeurl" alt class="consult-qrcode" />
            <div class="consult-tips">保存并扫描二维码图片，即可添加顾问微信</div>
            <div class="consult-submit" @click="goService()">立即咨询</div>
        </div>
    </div>
</template>

<script>
import store from "@/store";
export default {
    name: "com-consult",
    data() {
        return {
            options: "",
            userInfo: store.getters.getUserInfo,
            serviceUrl: "",
            isShow: true
        };
    },
    mounted() {
        // this.serviceUrl = `https://im.7x24cc.com/phone_webChat.html?accountId=N000000019197&chatId=025fde47-8010-4554-be25-eea1fb499763&agentExten=${this.userInfo.customercode}&phone=${this.userInfo.tel}&nickName=${this.userInfo.name}`;
    },
    methods: {
        /**
         * 关闭自身
         */
        close() {
            this.isShow = false;
        },
        goService() {
            this.isShow = false;
            /* eslint-disable */
            _53App.getApp('chat').openSdkUrl('float');
        }
    }
};
</script>

<style lang="scss" scoped>
.com-consult-index {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    z-index: 9999999;
    .consult-win {
        position: absolute;
        padding: 30px 40px;
        left: 50%;
        top: 50%;
        width: 460px;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        text-align: center;
    }
    .consult-title {
        text-align: left;
        color: #999;
        font-size: 28px;
    }
    .consult-name {
        margin-top: 15px;
        font-size: 36px;
        text-align: left;
    }
    .consult-close {
        position: absolute;
        right: 40px;
        top: 50px;
        width: 23px;
    }
    .consult-qrcode {
        margin-top: 20px;
        width: 444px;
        height: 444px;
        background: #555;
    }
    .consult-tips {
        color: #666;
        font-size: 24px;
        text-align: center;
        margin-top: 40px;
    }
    .consult-submit {
        width: 100%;
        background: #2e7bfe;
        height: 80px;
        line-height: 80px;
        color: white;
        margin-top: 30px;
    }
}
</style>
