<template>
  <transition name="fade">
    <div class="normal-submit-wrapper" v-if="isShow">
      <div class="s-submit">
        <img @click="close()" class="s-success" src="@/assets/success.png">
        <div class="s-title">提交成功</div>
        <div class="s-dis" v-if="options.describe">{{options.describe}}</div>
        <div class="s-dis" v-else>感谢您提供的宝贵意见，我们将不断优化改善服务，创造更好的用户体验。</div>
        <div class="s-btn" @click="close()">返回</div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'dnw-submit',
  data () {
    return {
      options: '',
      isShow: true
    }
  },
  mounted () {
  },
  methods: {
    close () {
      if(this.options.hasOwnProperty("callback")){
        this.options.callback(true)
      }
      this.isShow = false
    }
  }
}
</script>

<style lang="scss" scope>
  .normal-submit-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    z-index: 9999999;
    .s-submit {
      padding: 50px 40px;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 530px;
      transform: translate(-50%, -50%);
      background-color: #fff;
      border-radius: 8px;
      font-size: 18px;
      overflow: hidden;
      .s-success {
        width: 280px;
      }
      .s-title {
        margin-top: 40px;
        font-size: 36px;
        font-weight: bold;
      }
      .s-dis {
        margin-top: 20px;
        font-size: 26px;
        color: #999;
        padding: 0 30px;
      }
      .s-btn {
        width: 400px;
        height: 78px;
        margin: 40px auto 0 auto;
        color: #3367ff;
        border: 1px solid #3367ff;
        line-height: 78px;
        font-size: 32px;
        border-radius: 6px;
      }
    }
  }
</style>
