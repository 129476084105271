<template>
  <transition name="fade">
    <!--        单项续费-->
    <div class="normal-money-wrapper" v-if="isShow">
      <div class="s-confirm-tips">
        <img @click="close()" class="close-img pull-right" src="@/assets/close.png" />
        <div class="my-title" v-if="options.title ">{{options.title}}</div>
        <div class="my-describe">{{options.describe}}</div>
        <div class="my-describe" style="margin-top:-30px;">
          <div class="time">续费时长：</div>
          <div class="timer">
            <div class="timer1" @click="notAdd(xufeiTimer)">-</div>
            <div class="timer2">{{ xufeiTimer}}</div>
            <div class="timer3" @click="isAdd(xufeiTimer)">+</div>
          </div>
        </div>
        <div class="my-describe" style="text-align: left;margin-left:76px;">
          <span>费用：{{options.money * xufeiTimer}}元</span>
        </div>
        <div class="btnShow">
          <div class="con-bottom c-blue" @click="submit()">去支付</div>
          <div class="con-bottom" @click="close()">取消</div>
        </div>
      </div>
    </div>
    <!--        判断是否在同一城市-->
    <div class="normal-confirm-wrapper" v-if="isYes">
      <div class="s-confirm-tips" style="width:300px;">
        <img
          @click="close()"
          class="close-img pull-right"
          style="position:absolute;top:10px;right:10px;"
          src="@/assets/close.png"
        />
        <div class="my-title" v-if="options.title">{{options.title}}</div>
        <div class="my-describe">{{options.describe}}</div>
        <div class="btnShow">
          <div
            class="con-bottom c-blue"
            @click="notBad(options.param.saletypeid,options.param.userid)"
          >享优惠</div>
          <div class="con-bottom" @click="closeFree(options.param.saletypeid)">放弃优惠</div>
        </div>
      </div>
    </div>
    <!--        判断是否选择一起续费-->
    <div class="normal-confirm-wrapper" v-if="isDefren">
      <div class="s-confirm-tips" style="width:300px;">
        <img
          @click="close()"
          class="close-img pull-right"
          style="position:absolute;top:10px;right:10px;"
          src="@/assets/close.png"
        />
        <div class="my-title" v-if="options.title">{{options.title}}</div>
        <div class="my-describe">{{options.describe}}</div>
        <div class="btnShow">
          <div
            class="con-bottom c-blue"
            @click="notBad(options.param.saletypeid,options.param.userid)"
          >同意</div>
          <div class="con-bottom" @click="closeFree(options.param.saletypeid)">去单项续费</div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import router from "../../../router/index.js";
export default {
  name: "dnw-confirm",
  data() {
    return {
      options: "",
      showData: {
        userid: "",
        saletypeid: "",
        sjgjOrderHukou: {
          year: ""
        }
      },
      isShow: false, //单项续费
      isYes: false, //限时优惠
      isDefren: false, //判断是否选择一起续费
      Year: "", //档案年份，同时续费时
      xufeiTimer: "",
      //档案信息  同时续费时
      danganParam: {
        userid: "",
        remark: "",
        saletypeid: "",
        cityid: "",
        tel: "",
        time: "",
        sjgjOrderDangan: {
          year: ""
        }
      },
      //户口信息  同时续费时
      hukouParam: {
        year: "",
        cityid: "",
        saletypeid: "",
        settlemodeid: "",
        tel: ""
      },
      paramData: {
        //同时续费
        userid: "",
        remark: "",
        sjgjOrderDangan: {
          year: "",
          saletypeid: "",
          cityid: "",
          tel: ""
        },
        sjgjOrderHukou: {
          year: "",
          cityid: "",
          saletypeid: "",
          settlemodeid: "",
          tel: ""
        }
      }
    };
  },
  mounted() {
    // console.log(this.options);
    if (this.options.isDangan || this.options.isHuKou) {
      this.isYes = true; //-去限时优惠
    } else {
      this.isShow = true; //-放弃优惠去-单项续费
      if (this.options.param.saletypeid == 19 || this.options.param.saletypeid == 41) {
        this.getMyBusiness();
      } else {
        this.getDetail();
      }
    }
    if (this.options.param.sjgjOrderHukou.year != "") {
      this.xufeiTimer = this.options.param.sjgjOrderHukou.year; //续费年限
    } else {
      this.xufeiTimer = 1;
    }
  },
  methods: {
    /* 
      去支付--
      支付时判断是单项续费还是同时续费
     */
    submit() {
      if (this.options.param.saletypeid == 19 || this.options.param.saletypeid == 41) {
        //进入档案单项续费
        if (!this.options.isDangan) {
          this.isShow = false;
          this.$siaxios
            .post("order/saveDangAnXuFei", this.danganParam)
            .then(res => {
              router.forward({
                path: "/creatorder/orderdetail",
                query: { id: res.id }
              });
            });
        } else {
          this.isAgree(this.options.param.saletypeid); //同时续费
        }
      } else if (this.options.param.saletypeid == 22 || this.options.param.saletypeid == 42) {
        // 进入户口单项续费
        if (!this.options.isHuKou) {
          this.isShow = false;
          this.$siaxios
            .post("order/saveHuKouXuFei", this.hukouParam)
            .then(res => {
              router.forward({
                path: "/creatorder/orderdetail",
                query: { id: res.id }
              });
            });
        } else {
          this.isAgree(this.options.param.saletypeid); //同时续费
        }
      }
      this.isShow = false;
      this.isYes = false;
      this.isDefren = false;
    },
    //关闭弹窗
    close() {
      this.isShow = false;
      this.isYes = false;
      this.isDefren = false;
    },
    /*
      放弃优惠或者选择单项续费
    */
    closeFree(saletypeid) {
      this.options.describe = "";
      if (saletypeid == 19 || saletypeid == 41) {
        this.options.title = "档案续费";
        this.getMyBusiness();
        this.options.isDangan = false; //去支付时 判断是否为单项续费
      } else if (saletypeid == 22 || saletypeid == 42) {
        this.options.title = "户口续费";
        this.options.isHuKou = false; //去支付时 判断是否为单项续费
        this.getDetail();
      }
      this.isShow = true;
      // this.options.money = "";
      this.isYes = false;
      this.isDefren = false;
    },
    //减少续费时长
    notAdd(xufeiTimer) {
      if (this.xufeiTimer >= 1) {
        this.xufeiTimer = xufeiTimer - 1;
        return this.xufeiTimer;
      } else {
        return false;
      }
    },
    //增加续费时长
    isAdd(xufeiTimer) {
      this.xufeiTimer = xufeiTimer + 1;
      return this.xufeiTimer;
    },
    /* 
      同意一起续费 
      判断是否存在户口或者档案业务再去同时续费 
      如果此时是档案业务就去判断是否存在户口
      户口同理
    */
    notBad(saletypeid) {
      this.isYes = false;
      this.isDefren = false;
      this.options.describe = "";
      // 判断同时续费时，返回的续费金额是否为0
      // if(this.options.hmoney == 0){
      //   this.options.money = this.options.hmoney +
      // }
      this.options.money = this.options.hmoney; //同时续费时，展示户口的hxufei
      if (saletypeid == 19 || saletypeid == 41) {
        this.isYewu(4, "户口", this.options.param.userid);
      } else if (saletypeid == 22 || saletypeid == 42) {
        this.isYewu(3, "档案", this.options.param.userid);
      }
    },
    // 判断是否存在档案或者户口业务
    isYewu(type, title, userid) {
      this.$siaxios.get("order/getState/" + type + "/" + userid).then(res => {
        if (res) {
          this.options.title = "档案/落户续费";
          this.isShow = true;
          this.isYes = false;
          this.isDefren = false;
          // 存在去调取相对应的业务信息
          if (type == 4) {
            this.getDetail(); //获取户口信息
           
            this.options.money = this.options.hmoney;
            
          } else if (type == 3) {
            this.getMyBusiness(); //获取档案信息
          }
        } else {
          this.$tips({
            title: "提示",
            describe:
              type == 3
                ? "您没有办理相关的档案业务或档案已被提取"
                : "您没有办理相关的户口业务或户口已被迁出"
          });
          this.isYes = false;
        }
      });
    },
      //获取不同城市的续费价格
     getCityCharge(saletypeid) {
      this.$siaxios
        .get(
          "common/getBusinessCharge/" +
            this.options.param.cityid +
            "/" + saletypeid
            
        )
        .then(res => {  
            for (var i = 0; i < res.length; i++) {
              if (this.hukouParam.settlemode == res[i].taocan) {
                // this.xufeiPrice = res[i].xufei; //户口单独续费时
                this.options.hmoney = res[i].hdxufei; //同时续费时
              }
            
          }
        });
    },
    //获取户口或者档案年限
    getYear(type) {
      this.$siaxios
        .get("order/getYear/" + this.options.param.userid + "/" + type)
        .then(res => {
          this.Year = res;
        });
    },
    //获取档案信息
    getMyBusiness() {
      this.$siaxios
        .get("/dangan/getDanganInfo/" + this.options.param.userid)
        .then(res => {
          this.danganParam = {
            userid: this.options.param.userid,
            remark: res.remark,
            saletypeid: res.ywtype,
            cityid: res.cityid,
            tel: res.tel,
            time: res.duedate,
            sjgjOrderDangan: {
              year: this.xufeiTimer
            }
          };
        });
    },
    //获取户口信息
    getDetail() {
      this.$siaxios
        .get("hukou/getHuKouInfo/" + this.options.param.userid)
        .then(res => {
          this.hukouParam = {
            userid: this.options.param.userid,
            remark: res.userremark,
            saletypeid: res.ywtype,
            cityid: res.cityid,
            tel: res.phone,
            // time: res.duedate,
            settlemode: res.settlemode,
            sjgjOrderHukou: {
              year: this.xufeiTimer
            }
          };
           this.getCityCharge(this.hukouParam.saletypeid)
        });
    },
    /* 
      提交同时续费
      同时续费时，续费的时长费用都是统一的
     */
    isAgree(saletypeid) {
      if (saletypeid == 19 || saletypeid == 41) {
        this.paramData = {
          userid: this.options.param.userid,
          remark: this.options.param.remark,
          sjgjOrderDangan: {
            year: this.xufeiTimer,
            saletypeid: this.options.param.saletypeid,
            cityid: this.options.param.cityid,
            tel: this.options.param.tel
          },
          sjgjOrderHukou: {
            year: this.xufeiTimer,
            cityid: this.hukouParam.cityid,
            saletypeid: this.hukouParam.saletypeid,
            tel: this.hukouParam.tel
          }
        };
      } else if (saletypeid == 22 || saletypeid == 42) {
        this.paramData = {
          userid: this.options.param.userid,
          remark: this.options.param.remark,
          sjgjOrderDangan: {
            year: this.xufeiTimer,
            saletypeid: this.danganParam.saletypeid,
            cityid: this.danganParam.cityid,
            tel: this.danganParam.tel
          },
          sjgjOrderHukou: {
            year: this.xufeiTimer,
            cityid: this.options.param.cityid,
            saletypeid:  this.options.param.saletypeid,
            tel: this.options.param.tel
          }
        };
      }
      this.$siaxios
        .post("/order/saveDangAnHuKouXuFei", this.paramData)
        .then(res => {
          router.forward({
            path: "/creatorder/orderdetail",
            query: { id: res.id }
          });
        });
      this.isDefren = false;
    }
  }
};
</script>

<style lang="scss" scope>
.normal-money-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index: 9999999;

  .s-confirm-tips {
    position: absolute;
    padding-top: 40px;
    left: 50%;
    top: 50%;
    width: 600px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 8px;
    font-size: 18px;
    overflow: hidden;

    .close-img {
      margin-top: -10px;
      width: 26px;
      margin-right: 20px;
    }

    .my-title {
      font-size: 36px;
      color: #333;
      text-align: center;
      margin: 30px 0;
    }

    .my-describe {
      overflow: hidden;
      font-size: 26px;
      color: #a5a5a5;
      margin: 60px 0;
      white-space: pre-wrap;

      .time {
        float: left;
        margin-left: 100px;
      }

      .timer {
        float: left;

        .timer1 {
          width: 70px;
          height: 40px;
          line-height: 40px;
          border: 1px solid #a5a5a5;
          float: left;
        }

        .timer2 {
          width: 70px;
          height: 40px;
          line-height: 40px;
          border-top: 1px solid #a5a5a5;
          border-bottom: 1px solid #a5a5a5;
          float: left;
        }

        .timer3 {
          width: 70px;
          height: 40px;
          line-height: 40px;
          border: 1px solid #a5a5a5;
          float: left;
        }
      }
    }

    .btnShow {
      height: 90px;
      border-top: 30px;
      border-top: 1px solid #eee;

      .con-bottom {
        float: right;
        width: 49%;
        line-height: 80px;
        font-size: 32px;
        color: white;
        color: #ccc;

        &.c-blue {
          color: #397eff;
          border-left: 1px solid #eee;
        }
      }
    }
  }
}
</style>
