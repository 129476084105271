/*

提示组件：
  warning 警告
  success 成功

*/
import Vue from 'vue'
import Alert from './alert'
Alert.installAlert = function(options) {
    let str = ''
    if (options === undefined || options === null) {
        str = ''
    } else {
        str = options
    }
    const newAlert = Vue.extend(Alert)
    const component = new newAlert({ data: { str } }).$mount()
    document.getElementById('app-main').getElementsByTagName('div')[0].appendChild(component.$el)
}

export default Alert