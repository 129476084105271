/**
 * 社保 公积金月份选择器
 */
import Vue from 'vue'
import Month from './month'
Month.installMonth = function(options) {
  if (options === undefined || options === null) {
    options = {
      options: ''
    }
  } else if (typeof options === 'string' || typeof options === 'number') {
    options = {
      describe: options
    }
  } else {
    options = {
      options: options
    }
  }
  const newMonth = Vue.extend(Month)
  const component = new newMonth({
    data: options
  }).$mount()
  document.getElementById('app-main').getElementsByTagName('div')[0].appendChild(component.$el)
}

export default Month