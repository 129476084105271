<template>
  <div class="plugin-pay-type">
    <div class="module-item" v-for="item in payList" :key="item.paytype">
      <!-- <img :src="getPayTypeInfo(item.paytype).img" class="icon" alt /> -->
      <span class="payname">{{getPayTypeInfo(item.paytype).name}}</span>
      <div class="pay-pos">
        <v-radio v-model="paytype" v-bind:value="item.paytype"></v-radio>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import wallet from "@/assets/components/pay/wallet.png";
import wechat from "@/assets/components/pay/wechat.png";
import alipay from "@/assets/components/pay/alipay.png";
import { mapGetters } from "vuex";
export default {
  name: "v-pay",
  model: {
    prop: "msg",
    event: "submit"
  },
  props: {
    msg: "",
    saletypeids: ""
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  data() {
    return {
      payList: [],
      paytype: ""
    };
  },
  mounted() {
    this.paytype = this.msg;
  },
  watch: {
    paytype() {
      this.$emit("submit", this.paytype);
    }
  },
  methods: {
    getPayTypeInfo(id) {
      if (id == 1) {
        return {
          name: "微信",
          img: wechat
        };
      } else if (id == 2) {
        return {
          name: "支付宝",
          img: alipay
        };
      } else if (id == 3) {
        return {
          name: "线下支付",
          img: wallet
        };
      }
    },
    /**
     * 获取支付方式
     */
    getPayType(ids) {
      if (!this.userInfo.device) {
        this.payList = [
          {
            paytype: 1
          }
        ];
      } else if (ids.length <= 1) {
        this.$siaxios.get(`/order/getPayType/${ids[0]}`).then(res => {
          this.payList = res;
        });
      } else {
        let tempList = [];
        ids.forEach(element => {
          this.$siaxios.get(`/order/getPayType/${element}`).then(res => {
            res.forEach(child => {
              if (tempList.indexOf(child.paytype) >= 0) {
                this.payList.push(child);
              } else {
                tempList.push(child.paytype);
              }
            });
          });
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.plugin-pay-type {
  .title {
    height: 70px;
    line-height: 70px;
    border-bottom: 1px solid #eeeeee;
    padding: 0 30px;
    background: #fff;
    h2 {
      position: relative;
      font-size: 28px;
      font-weight: normal;
      margin-left: 10px;
      width: 50%;
      float: left;
      &:before {
        content: "";
        position: absolute;
        width: 4px;
        height: 23px;
        top: 23px;
        left: -10px;
        margin-right: 20px;
        font-weight: bolder;
        background-color: #2378ff;
      }
    }
  }
  .module-item {
    background: white;
    padding: 20px 30px;
    font-size: 26px;
    .payname {
      margin-left: 20px;
    }
    .icon {
      margin-bottom: -12px;
      width: 40px;
      height: 40px;
    }
    .pay-pos {
      float: right;
      width: 34px;
      height: 34px;
    }
  }
  .agreement {
    margin-top: 5px;
    padding: 17px 30px;
    font-size: 24px;
    color: #666;
    .tips {
      margin-left: 15px;
    }
  }
}
</style>
