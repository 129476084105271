/*

提示组件：
  warning 警告
  success 成功

*/
import Vue from 'vue'
import Submit from './Submit'
Submit.installSubmit = function(options) {
  if (options === undefined || options === null) {
    options = {
      options: ''
    }
  } else if (typeof options === 'string' || typeof options === 'number') {
    options = {
      describe: options
    }
  } else {
    options = {
      options: options
    }
  }
  const newSubmit = Vue.extend(Submit)
  const component = new newSubmit({
    data: options
  }).$mount()
  document.getElementById('app-main').getElementsByTagName('div')[0].appendChild(component.$el)
}

export default Submit