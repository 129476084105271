<template>
  <transition name="fade">
    <div class="normal-tips-wrapper" v-if="isShow">
      <div class="s-tips-tips">
        <img @click="close()" class="close-img pull-right" src="@/assets/close.png" />
        <div class="my-title">{{options.title?options.title:'提示'}}</div>
        <div class="my-describe">{{options.describe}}</div>
        <div class="btnShow">
          <a class="gowrite" href="tel:0532-85873655" v-if="options.showtel">{{options.btn}}</a>
          <div class="con-bottom" @click="submit()">确定</div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "dnw-tips",
  data() {
    return {
      options: "",
      isShow: true
    };
  },
  mounted() {},
  methods: {
    submit() {
      if (this.options.hasOwnProperty("callback")) {
        this.options.callback(true);
      }
      this.isShow = false;
    },
    close() {
      this.isShow = false;
    }
  }
};
</script>

<style lang="scss" scope>
.normal-tips-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index: 9999999;
  .s-tips-tips {
    padding: 40px 40px 30px 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 530px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 14px;
    font-size: 18px;
    overflow: hidden;
    .close-img {
      width: 26px;
    }
    .my-title {
      font-size: 36px;
      color: #333;
      text-align: center;
      margin-top: -10px;
      font-weight: bold;
    }
    .my-describe {
      font-size: 26px;
      padding: 50px 0;
      color: #333;
      text-align: center;
      white-space: pre-wrap;
    }
    .btnShow {
      display: flex;
      align-items: center;
      justify-content: center;
      .gowrite {
        width: 200px;
        padding: 60px 0;
        color: #707070;
        font-size: 30px;
        margin-right: 20px;
        margin-bottom: 3vw;
      }
      .con-bottom {
        display: block !important;
        // margin-left: 80px;
        width: 340px;
        height: 80px;
        line-height: 80px;
        font-size: 32px;
        color: white;
        background: #1a55dd;
        border-radius: 40px;
        text-align: center;
      }
    }
  }
}
</style>
