<template>
    <div class="top-nav" :style="{paddingTop: $statusBarHeight + 'px' }">
        <div class="goback" @click="goback()" v-if="showBack">
            <img src="@/assets/back-black.png" class="back-icon" />
        </div>
        <span class="nav-name">{{title}}</span>
        <div class="goback" @click="goShare" v-if="showShare">
            <img src="@/assets/share.png" class="share-icon" />
        </div>
        <share :data="shareData" v-if="shareShow" @close="shareShow=false"></share>
    </div>
</template>

<script>
import { eventBus } from "@/main";
import { mapGetters } from "vuex";
import share from '@/components/plug-in/share/index'
export default {
    components: { share },
    data() {
        return {
            title: "",
            informationDetail: {},
            shareData: {},
            shareShow: false
        };
    },
    watch: {
        $route() {
            this.setTitle();
        }
    },
    computed: {
        showBack() {
            return this.$route.meta.showBack == undefined
        },
        showShare() {
            return this.$route.path == "/information/detail"
        },
        ...mapGetters({
            userInfo: "getUserInfo"
        })
    },
    created() {
        eventBus.$on("infomationDetails", val => {
            this.informationDetail = JSON.parse(JSON.stringify(val));
        });
    },
    mounted() {
        this.setTitle();
    },
    methods: {
        goback() {
            if(this.$route.path.indexOf('/creatorder/orderdetail')>-1){
               this.$router.replace('/creatorder/orderlist')
            }else if(this.$route.path.indexOf('/creatorder/orderlist')>-1){
                this.$router.replace('/mine/index')
            }else{
                this.$router.back(-1);
            }            
        },
        setTitle() {
            this.title = document.title;       
        },
        goShare() {
            let { imgurl, introduce, title } = this.informationDetail;
            this.shareData = {
                shareurl: window.location.href,
                imgurl,
                introduce,
                title
            };
            this.shareShow = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.top-nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 90px;
    line-height: 90px;
    color: #333;
    background: white;
    font-size: 34px;
    text-align: center;
    border-bottom: 1px solid #eee;
    .goback {
        .back-icon {
            position: absolute;
            bottom: 30px;
            left: 30px;
            width: 18px;
        }
        .share-icon {
            position: absolute;
            bottom: 30px;
            right: 30px;
            width: 36px;
        }
    }
}
</style>
