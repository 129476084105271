<template>
  <transition name="fade">
    <div class="normal-confirm-wrapper" v-if="isShow">
      <div class="s-confirm-tips">
        <div class="content">
          <img @click="close()" class="close-img pull-right" src="@/assets/close.png" />
          <div class="my-title" v-if="options.title">{{options.title}}</div>
          <div class="my-describe">{{options.describe}}</div>
        </div>
        <div class="btnShow">
          <div class="con-bottom c-blue" @click="submit()">确定</div>
          <div class="con-bottom" @click="close()">取消</div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>

export default {
  name: "dnw-confirm",
  data() {
    return {
      options: "",
      isShow: true
    };
  },
  mounted() {},
  methods: {
    submit() {
      if (this.options.hasOwnProperty("callback")) {
        this.options.callback(true);
      }
      this.isShow = false;
    },
    close() {
      if (this.options.hasOwnProperty("callback")) {
        this.options.callback(false);
      }
      this.isShow = false;
    }
  }
};
</script>

<style lang="scss" scope>
.normal-confirm-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index: 9999999;
  .s-confirm-tips {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 20px;
    font-size: 18px;
    overflow: hidden;
    .content{
      padding: 15px 40px 15px;
      width: 520px;
    }
    .close-img {
      margin-top: 10px;
      width: 26px;
      margin-right: 10px;
    }
    .my-title {
      font-size: 36px;
      color: #333;
      text-align: center;
      margin: 30px 0;
      font-weight: bold;
      margin-left: 30px;
    }
    .my-describe {
      font-size: 26px;
      color: #666;
      text-align: center;
      margin: 60px 0;
      white-space: pre-wrap;
      line-height: 40px;
      letter-spacing: 2px;
      span{
          color: #3669F8;
          padding: 0 5px;
      }
    }
    .btnShow {
      height: 90px;
      border-top: 30px;
      border-top: 1px solid #eee;
      .con-bottom {
        float: right;
        width: 49%;
        line-height: 80px;
        font-size: 32px;
        color: #666;
        &.c-blue {
          color: #397eff;
          border-right:1px solid #ccc;
        }
      }
    }
  }
}
</style>
