import Vue from 'vue'
import App from './App.vue'
import router from './router/'
import store from './store/'
import wxSdk from 'weixin-js-sdk'
import qs from 'qs'
import axios from './lib/axios'
import siaxios from './lib/simple-axios'
import Plugin from '@/components/plug-in/install' //通用集合组件
import echarts from 'echarts'
import '@/sass/main.scss'
import "swiper/css/swiper.min.css"
import VueClipboard from 'vue-clipboard2'
import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)

Vue.use(Plugin)
Vue.use(VueClipboard)
Vue.prototype.$qs = qs
Vue.prototype.$echarts = echarts
Vue.prototype.$axios = axios
Vue.prototype.$siaxios = siaxios
Vue.config.productionTip = false
Vue.prototype.$isPlus = false
Vue.prototype.$statusBarHeight = 0
    // 在线客服
var _53code = document.createElement("script");
_53code.src = "https://tb.53kf.com/code/code/a7ff6be331928f5923f9adc0edaa0f484/3";
var s = document.getElementsByTagName("script")[0];
s.parentNode.insertBefore(_53code, s);

// document.addEventListener('UniAppJSBridgeReady', function() {
//     /* eslint-disable */
//     uni.getEnv(function(res) {
//         Vue.prototype.$isPlus = res.plus ? res.plus : false
//     });
//     let sysInfo = navigator.appVersion
//     let data = {
//         source: sysInfo.indexOf('Android') > -1 ? 'android' : 'ios'
//     }
//     let userInfo = JSON.parse(JSON.stringify(store.getters.getUserInfo))
//         //ios是字符串  android是对象
//     userInfo.device = data
//     store.commit("setMyUserInfo", userInfo);
//     // eslint-disable-next-line
//     Vue.prototype.$statusBarHeight = Vue.prototype.$isPlus ? plus.navigator.getStatusbarHeight() : 0

// })
export const eventBus = new Vue()
new Vue({
    router,
    store,
    created() {
        //将方法挂载到全局中
        // window.initData = this.initData;
        window.goRouter = this.goRouter;
        this.iniSignature()
    },
    methods: {
        /**
         * app 调用方法
         * @param {*} data
         */
        initData(data) {
            let userInfo = JSON.parse(JSON.stringify(store.getters.getUserInfo))
                //ios是字符串  android是对象
            if (typeof data == 'string') userInfo.device = JSON.parse(data)
            else userInfo.device = data
            store.commit("setMyUserInfo", userInfo);
        },
        goRouter(obc) {
            this.$router.forward(obc);
        },
        /**
         * 初始化分享配置
         */
        iniSignature() {
            let params = {
                url: location.href.split("#")[0]
            };
            this.$axios
                .post("/share/sgture", this.$qs.stringify(params))
                .then(function(response) {
                    let data = response.data.data;
                    wxSdk.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: data.appid, // 必填，公众号的唯一标识
                        timestamp: data.timestamp, // 必填，生成签名的时间戳0
                        nonceStr: data.nonceStr, // 必填，生成签名的随机串
                        signature: data.signature, // 必填，签名
                        jsApiList: [
                                "hideAllNonBaseMenuItem",
                                "showAllNonBaseMenuItem",
                                "updateAppMessageShareData",
                                "updateTimelineShareData",
                                "openLocation"
                            ] // 必填，需要使用的JS接口列表
                    });
                });
        }
    },
    render: h => h(App)
}).$mount('#app')