import axios from './axios'
import tips from '@/components/plug-in/tips'
/* eslint-disable */
let http = {
    vueComponents: '',
    post: '',
    get: '',
    setVThis: '',
}

/**
 * @param {*} api 接口地址
 * @param {*} data 参数
 * @param {*} showSTips 正常返回是否提示
 * @description axio封装post对象
 */
http.post = (api, data, showSTips = true) => {
    return new Promise((resolve, reject) => {
        axios.post(api, data).then((res) => {
            if (res.data.success) {
                if (showSTips) {
                    tips.installTips({
                        title: '提示',
                        describe: res.data.errMsg,
                    })
                }
                resolve(res.data.data)
            } else {
                tips.installTips({
                    title: '提示',
                    describe: res.data.errMsg,
                })
            }
        })
    })
}

/**
 * @param {*} api 接口地址
 * @param {*} data 参数
 * @param {*} showSTips 正常返回是否提示
 * @description axio封装get对象
 */
http.get = (api, showSTips) => {
    return new Promise((resolve, reject) => {
        axios.get(api).then((res) => {
            if (res.data.success) {
                if (showSTips) {
                    tips.installTips({
                        title: '提示',
                        describe: res.data.errMsg,
                    })
                }
                resolve(res.data.data)
            } else {
                tips.installTips({
                    title: '提示',
                    describe: res.data.errMsg,
                })
            }
        })
    })
}

export default http