import Layout from '@/components/layout' //布局
export default [{
    path: '/',
    name: 'layout',
    component: Layout,
    redirect: "/index/index",
    children: [{
            path: '/login/agreement',
            meta: { title: "用户协议" },
            component: () =>
                import ('@/views/login/agreement')
        },
        {
            path: '/login/registeragreement',
            meta: { title: "注册协议" },
            component: () =>
                import ('@/views/login/registerAgreement')
        },
        {
            path: '/login/privacyagreement',
            meta: { title: "隐私协议" },
            component: () =>
                import ('@/views/login/privacyAgreement')
        },
        {
            path: '/login/login',
            meta: { title: "登录" },
            component: () =>
                import ('@/views/login/login')
        },
        {
            path: '/login/wechatlogin',
            meta: { title: "" },
            component: () =>
                import ('@/views/login/wechatlogin')
        },
        {
            path: '/login/register',
            meta: { title: "注册" },
            component: () =>
                import ('@/views/login/register')
        },
        {
            path: '/login/forget',
            meta: { title: "忘记密码" },
            component: () =>
                import ('@/views/login/forget')
        },
        {
            path: "/index/index",
            name: "IndexPage",
            meta: {
                title: "档管家",
                keepAlive: true,
                showBack: false,
                showBottom: true
            },
            component: () =>
                import ('@/views/index/index')
        },
        {
            path: "/index/search",
            name: "IndexSearch",
            meta: {
                title: "搜索",
                keepAlive: true,
            },
            component: () =>
                import ('@/views/index/Search')
        },
        {
            path: "/classify/employment",
            name: "ClassifyEmployment",
            meta: {
                title: "就业",
            },
            component: () =>
                import ('@/views/classify/employment')
        },
        {
            path: "/classify/entrepreneurship",
            name: "ClassifyEntrepreneurship",
            meta: {
                title: "创业",
            },
            component: () =>
                import ('@/views/classify/entrepreneurship')
        },
        {
            path: "/creatorder/accumulationfund",
            name: "Accumulationfund",
            meta: {
                title: "公积金缴费",
            },
            component: () =>
                import ('@/views/creatorder/accumulationfund/accumulationfund')
        },
        {
            path: "/creatorder/general",
            name: "General",
            meta: {
                title: "",
            },
            component: () =>
                import ('@/views/creatorder/general')
        },
        {
            path: "/creatorder/consult",
            name: "CreatorderConsult",
            meta: {
                title: "劳动咨询",
            },
            component: () =>
                import ('@/views/creatorder/consult')
        },
        {
            path: "/mine/renew",
            meta: {
                title: "户口档案续费缴费",
            },
            component: () =>
                import ('@/views/mine/renew')
        },
        {
            path: "/creatorder/creatgeneral",
            name: "Creatgeneral",
            meta: {
                title: "创建订单",
            },
            component: () =>
                import ('@/views/creatorder/creatgeneral')
        },
        {
            path: "/creatorder/success",
            name: "CreatgeneralSuccess",
            meta: {
                title: "支付成功",
            },
            component: () =>
                import ('@/views/creatorder/success')
        },

        {
            path: "/creatorder/socialsecurity",
            name: "Creatorder",
            meta: {
                title: "社保缴费",
            },
            component: () =>
                import ('@/views/creatorder/socialsecurity/socialsecurity')
        },
        {
            path: "/creatorder/creatsocialsecurity",
            name: "Creatsocialsecurity",
            meta: {
                title: "确认服务信息",
            },
            component: () =>
                import ('@/views/creatorder/socialsecurity/creatsocialsecurity')
        },
        {
            path: "/expressmail/index",
            name: "Expressmail",
            meta: {
                title: "快递服务",
                keepAlive: true
            },
            component: () =>
                import ('@/views/expressmail/index')
        },
        {
            path: "/expressmail/address",
            name: "Expressadd",
            meta: {
                title: "地址管理",
            },
            component: () =>
                import ('@/views/expressmail/address')
        },
        {
            path: "/expressmail/detail",
            name: "Expressdetail",
            meta: {
                title: "地址管理",
            },
            component: () =>
                import ('@/views/expressmail/detail')
        },
        {
            path: "/expressmail/send",
            name: "Expresssend",
            meta: {
                title: "资料邮寄",
            },
            component: () =>
                import ('@/views/expressmail/send')
        },
        {
            path: "/expressmail/expressarchives",
            name: "expressarchives",
            meta: {
                title: "档案邮寄",
            },
            component: () =>
                import ('@/views/expressmail/expressarchives')
        },

        {
            path: "/creatorder/payback",
            name: "CreatPaybackorder",
            meta: {
                title: "补缴订单",
            },
            component: () =>
                import ('@/views/creatorder/payback')
        },
        {
            path: "/creatorder/orderlist",
            name: "Orderlist",
            meta: {
                title: "订单列表",
                // keepAlive: true,
                isBack: true
            },
            component: () =>
                import ('@/views/creatorder/orderlist')
        },
        {
            path: "/creatorder/orderdetail",
            name: "Orderdetail",
            meta: {
                title: "订单详情",
            },
            component: () =>
                import ('@/views/creatorder/orderdetail')
        },
        {
            path: "/information/list",
            name: "InformationList",
            meta: {
                title: "资讯",
                showBottom: true,
                showBack: false,
                keepAlive: true,
            },
            component: () =>
                import ('@/views/information/list')
        },
        {
            path: "/mine/index",
            name: "Mine",
            meta: {
                title: "我的",
                showBack: false,
                showBottom: true
            },
            component: () =>
                import ('@/views/mine/index')
        },
        {
            path: "/mine/business",
            name: "Businiess",
            meta: {
                title: "我的业务",
            },
            component: () =>
                import ('@/views/mine/business')
        },
        {
            path: "/mine/otherBusiness",
            name: "otherBusiness",
            meta: {
                title: "其他业务",
            },
            component: () =>
                import ('@/views/mine/otherBusiness')
        },
        {
            path: "/mine/obtain",
            name: "obtain",
            meta: {
                title: "就业",
            },
            component: () =>
                import ('@/views/mine/obtain')
        },
        {
            path: "/mine/entrepreneurship",
            name: "entrepreneurship",
            meta: {
                title: "创业",
            },
            component: () =>
                import ('@/views/mine/entrepreneurship')
        },
        {
            path: "/mine/labor",
            name: "labor",
            meta: {
                title: "劳动关系咨询",
            },
            component: () =>
                import ('@/views/mine/labor')
        },
        {
            path: "/mine/authentication",
            name: "Authentication",
            meta: {
                title: "实名认证",
            },
            component: () =>
                import ('@/views/mine/authentication')
        },
        {
            path: "/mine/archives",
            name: "minearchives",
            meta: {
                title: "档案",
                keepAlive: true
            },
            component: () =>
                import ('@/views/mine/archives')
        },
        {
            path: "/mine/document-query",
            name: "document-query",
            meta: {
                title: "档案查询",
            },
            component: () =>
                import ('@/views/mine/document-query')
        },
        {
            path: "/gallery/archives-use",
            name: "archives-use",
            meta: {
                title: "档案用途",
            },
            component: () =>
                import ('@/views/gallery/archives-use')
        },
        {
            path: "/gallery/prove",
            name: "archives-prove",
            meta: {
                title: "档案证明",
            },
            component: () =>
                import ('@/views/gallery/prove')
        },
        {
            path: "/gallery/prove-find",
            name: "archives-find",
            meta: {
                title: "档案找回",
            },
            component: () =>
                import ('@/views/gallery/prove-find')
        },
        {
            path: "/gallery/prove-zhengli",
            name: "prove-zhengli",
            meta: {
                title: "档案整理",
            },
            component: () =>
                import ('@/views/gallery/prove-zhengli')
        },
        {
            path: "/gallery/prove-profile",
            name: "prove-profile",
            meta: {
                title: "档案补办",
            },
            component: () =>
                import ('@/views/gallery/prove-profile')
        },
        {
            path: "/gallery/prove-active",
            name: "archives-active",
            meta: {
                title: "档案激活",
            },
            component: () =>
                import ('@/views/gallery/prove-active')
        },
        {
            path: "/mine/borrowedRecord",
            name: "borrowedRecord",
            meta: {
                title: "借阅记录",
                keepAlive: true
            },
            component: () =>
                import ('@/views/mine/borrowedRecord')
        },
        {
            path: "/mine/stopPayment",
            name: "stopPayment",
            meta: {
                title: "申请停保",
                keepAlive: true
            },
            component: () =>
                import ('@/views/mine/stopPayment')
        },
        {
            path: "/mine/recordDetail",
            name: "recordDetail",
            meta: {
                title: "记录详情",

            },
            component: () =>
                import ('@/views/mine/recordDetail')
        },
        {
            path: "/mine/creatarchives",
            name: "creatarchive",
            meta: {
                title: "档案借阅",
            },
            component: () =>
                import ('@/views/mine/creatarchives')
        },
        {
            path: "/gallery/danganbro",
            name: "danganbro",
            meta: {
                title: "档案借阅-介绍",
            },
            component: () =>
                import ('@/views/gallery/danganbro')
        },
        {
            path: "/mine/backarchives",
            name: "backarchive",
            meta: {
                title: "档案归还",
            },
            component: () =>
                import ('@/views/mine/backarchives')
        },
        {
            path: "/gallery/danganbanli",
            name: "danganbanli",
            meta: {
                title: "档案办理",
            },
            component: () =>
                import ('@/views/gallery/danganbanli')
        },
        {
            path: "/gallery/dangancd",
            name: "dangancd",
            meta: {
                title: "档案存档",
            },
            component: () =>
                import ('@/views/gallery/dangancd')
        },
        {
            path: "/gallery/dangantd",
            name: "dangantd",
            meta: {
                title: "档案提档",
            },
            component: () =>
                import ('@/views/gallery/dangantd')
        },
        {
            path: "/gallery/hukoubanli",
            name: "hukoubanli",
            meta: {
                title: "户口办理",
            },
            component: () =>
                import ('@/views/gallery/hukoubanli')
        },
        {
            path: "/gallery/danganreturn",
            name: "danganreturn",
            meta: {
                title: "档案归还-详情",
            },
            component: () =>
                import ('@/views/gallery/danganreturn')
        },
        {
            path: "/mine/extractArchives",
            name: "extractArchives",
            meta: {
                title: "档案提取",
            },
            component: () =>
                import ('@/views/mine/extractArchives')
        },
        {
            path: "/gallery/dangantq",
            name: "dangantq",
            meta: {
                title: "档案提取-介绍",
            },
            component: () =>
                import ('@/views/gallery/dangantq')
        },
        {
            path: "/mine/residence",
            name: "mineresidence",
            meta: {
                title: "户口",
            },
            component: () =>
                import ('@/views/mine/residence')
        },
        {
            path: "/mine/residenceborrow",
            name: "residenceBorrow",
            meta: {
                title: "户口借阅",
            },
            component: () =>
                import ('@/views/mine/residenceBorrow')
        },
        {
            path: "/mine/borrowedRecord-hukou",
            name: "borrowedRecord-hukou",
            meta: {
                title: "户口借阅记录",
                keepAlive: true,
                isBack: true
            },
            component: () =>
                import ('@/views/mine/BorrowedRecord-hukou')
        },
        {
            path: "/mine/residenceBack",
            name: "residenceBack",
            meta: {
                title: "户口归还",
            },
            component: () =>
                import ('@/views/mine/residenceBack')
        },
        {
            path: "/mine/emigration",
            name: "residenceEmigration",
            meta: {
                title: "户口迁出",
            },
            component: () =>
                import ('@/views/mine/emigration')
        },
        {
            path: "/gallery/residence-emigration",
            name: "residence-emigration",
            meta: {
                title: "户口迁出-介绍",
            },
            component: () =>
                import ('@/views/gallery/residence-emigration')
        },
        {
            path: "/gallery/cut-from",
            name: "cut-from",
            meta: {
                title: "单页借取",
            },
            component: () =>
                import ('@/views/gallery/cut-from')
        },
        {
            path: "/gallery/page-return",
            name: "page-return",
            meta: {
                title: "单页归还",
            },
            component: () =>
                import ('@/views/gallery/page-return')
        },
        {
            path: "/gallery/page-add",
            name: "page-add",
            meta: {
                title: "单页补办",
            },
            component: () =>
                import ('@/views/gallery/page-add')
        },
        {
            path: "/gallery/no-house",
            name: "no-house",
            meta: {
                title: "无房证明开具",
            },
            component: () =>
                import ('@/views/gallery/no-house')
        },
        {
            path: "/mine/updatetel",
            name: "Updatetel",
            meta: {
                title: "更换手机号",
            },
            component: () =>
                import ('@/views/mine/updatetel')
        },
        {
            path: "/mine/balance",
            name: "balance",
            meta: {
                title: "我的盈余",
            },
            component: () =>
                import ('@/views/mine/balance')
        },
        {
            path: "/mine/paybackorder",
            name: "Paybackorder",
            meta: {
                title: "补缴订单",
                keepAlive: true
            },
            component: () =>
                import ('@/views/mine/paybackorder')
        },
        {
            path: "/mine/userdetailinfo",
            name: "userDetailInfo",
            meta: {
                title: "用户资料",
            },
            component: () =>
                import ('@/views/mine/userDetailInfo')
        },
        {
            path: "/information/detail",
            name: "InformationDetail",
            meta: {
                title: "资讯详情",
            },
            component: () =>
                import ('@/views/information/detail')
        },
        {
            path: "/mine/service",
            name: "Service",
            meta: {
                title: "专属顾问",
                showBack: false,
                showBottom: true
            },
            component: () =>
                import ('@/views/mine/service')
        },
        {
            path: "/mine/buyhouse",
            meta: {
                title: "烟台买房",
            },
            component: () =>
                import ("@/views/mine/buyhouse")
        },
        {
            path: "/mine/jinan-luohu",
            meta: {
                title: "济南落户",
            },
            component: () =>
                import ("@/views/mine/jinan-luohu")
        },
        {
            path: "/mine/jinan-buyhouse",
            meta: {
                title: "济南买房",
            },
            component: () =>
                import ("@/views/mine/jinan-buyhouse")
        },
        {
            path: "/mine/jinan-school",
            meta: {
                title: "济南上学",
            },
            component: () =>
                import ("@/views/mine/jinan-school")
        },
        {
            path: "/gallery/QD-buyhouse",
            meta: {
                title: "青岛买房",
            },
            component: () =>
                import ("@/views/gallery/QD-buyhouse")
        },
        {
            path: "/gallery/QD-school",
            meta: {
                title: "青岛子女上学",
            },
            component: () =>
                import ("@/views/gallery/QD-school")
        },
        {
            path: "/gallery/QD-residence",
            meta: {
                title: "青岛落户",
            },
            component: () =>
                import ("@/views/gallery/QD-residence")
        },
        {
            path: "/mine/school",
            meta: {
                title: "烟台入学条件",
            },
            component: () =>
                import ("@/views/mine/school")
        },
        {
            path: "/mine/feedback",
            name: "Feedback",
            meta: {
                title: "专属顾问",
            },
            component: () =>
                import ('@/views/mine/feedback')
        },
        {
            path: "/mine/aboutus",
            name: "Aboutus",
            meta: {
                title: "关于档管家",
            },
            component: () =>
                import ('@/views/mine/aboutus')
        },
        {
            path: "/mine/substitute",
            name: "Substitute",
            meta: {
                title: "代缴",
            },
            component: () =>
                import ('@/views/mine/substitute')
        },
        {
            path: "/mine/message",
            name: "Message",
            meta: {
                title: "消息列表",
                keepAlive: true,
                isBack: true
            },
            component: () =>
                import ('@/views/mine/message')
        },
        {
            path: "/test/test",
            name: "Test",
            meta: {
                title: "测试",
            },
            component: () =>
                import ('@/views/test/test')
        },
        {
            path: "/classify/list",
            name: "Classify",
            meta: {
                title: "服务列表",
                keepAlive: true,
                showBack: false,
                showBottom: true
            },
            component: () =>
                import ('@/views/classify/List')
        },
        {
            path: "/none/noorder",
            meta: {
                title: "暂无记录",
            },
            component: () =>
                import ('@/views/nonepage/noorder')
        },
        {
            path: "/socialSecurity/list",
            meta: {
                title: "社保问问",
            },
            component: () =>
                import ('@/views/questions/question/SocialList')
        },
        {
            path: "/gallery/securityabouts",
            meta: {
                title: "社保代缴协议",
            },
            component: () =>
                import ('@/views/gallery/securityabouts')
        },
        {
            path: "/socialSecurity/commonPolicy",
            meta: {
                title: "政策列表",
            },
            component: () =>
                import ('@/views/questions/common/PolicyList')
        },
        {
            path: "/pension/list",
            meta: {
                title: "养老保险",
            },
            component: () =>
                import ('@/views/questions/question/Pension')
        },
        {
            path: "/socialSecurity/pensionList",
            meta: {
                title: "养老保险",
            },
            component: () =>
                import ('@/views/questions/question/PensionList')
        },
        {
            path: "/accumulationFund/list",
            meta: {
                title: "公积金问问",
            },
            component: () =>
                import ("@/views/questions/question/AccumulationFund")
        },
        {
            path: "/gallery/GJJabouts",
            meta: {
                title: "公积金服务协议",
            },
            component: () =>
                import ("@/views/gallery/GJJabouts")
        },
        {
            path: "/gallery/otherAbouts",
            meta: {
                title: "其他服务协议",
            },
            component: () =>
                import ("@/views/gallery/otherAbouts")
        },
        {
            path: "/gallery/gjjfuwu",
            meta: {
                title: "公积金服务",
            },
            component: () =>
                import ("@/views/gallery/GJJfuwu")
        },
        {
            path: "/gallery/advantage",
            meta: {
                title: "公积金缴纳优势",
            },
            component: () =>
                import ("@/views/gallery/advantage")
        },
        {
            path: "/gallery/gongshangfx",
            meta: {
                title: "工伤保险",
            },
            component: () =>
                import ("@/views/gallery/shebaofx")
        },
        {
            path: "/gallery/hurtPensionList",
            meta: {
                title: "工伤保险",
            },
            component: () =>
                import ('@/views/gallery/HurtPensionList')
        },
        {
            path: "/gallery/shebaoka",
            meta: {
                title: "社保卡"
            },
            component: () =>
                import ("@/views/gallery/shebaoka")
        },
        {
            path: "/gallery/shebaokaPension",
            meta: {
                title: "社保卡"
            },
            component: () =>
                import ("@/views/gallery/ShebaokaPension")
        },
        {
            path: "/gallery/shengyubx",
            meta: {
                title: "生育保险"
            },
            component: () =>
                import ("@/views/gallery/shenyubx")
        },
        {
            path: "/gallery/shengyuPension",
            meta: {
                title: "生育保险"
            },
            component: () =>
                import ("@/views/gallery/ShenyuPension")
        },
        {
            path: "/gallery/shiyebx",
            meta: {
                title: "失业保险",
            },
            component: () =>
                import ("@/views/gallery/shiyebx")
        },
        {
            path: "/gallery/shiyePension",
            meta: {
                title: "失业保险",
            },
            component: () =>
                import ("@/views/gallery/ShiyePension")
        },
        {
            path: "/gallery/yiliaobx",
            meta: {
                title: "医疗保险",
            },
            component: () =>
                import ("@/views/gallery/yiliaobx")
        },
        {
            path: "/gallery/yiliaoPension",
            meta: {
                title: "医疗保险",
            },
            component: () =>
                import ("@/views/gallery/YiliaoPension")
        },
        {
            path: '/graduate/trusteeshipService',
            meta: {
                title: '毕业生签约托管服务'
            },
            component: () =>
                import ("@/views/graduate/TrusteeshipService")
        },
        {
            path: "/gallery/securityfw",
            name: 'Securityfw',
            meta: {
                title: "社保服务",

            },
            component: () =>
                import ("@/views/gallery/securityfw")
        },
        {
            path: "/gallery/graduatefw",
            name: "Graduatefw",
            meta: {
                title: "毕业生服务",
                keepAlive: true
            },
            component: () =>
                import ("@/views/gallery/graduatefw")
        },
        {
            path: "/gallery/small-encyclopedia",
            meta: {
                title: "毕业小百科",
            },
            component: () =>
                import ("@/views/gallery/small-encyclopedia")
        },
        {
            path: "/gallery/xxsercive",
            meta: {
                title: "信息查询",
            },
            component: () =>
                import ("@/views/gallery/xxService")
        },
        {
            path: '/graduates/serviceGuide',
            meta: {
                title: '办事指南'
            },
            component: () =>
                import ("@/views/graduate/ServiceGuide")
        },
        {
            path: '/mine/set',
            meta: {
                title: '设置'
            },
            component: () =>
                import ("@/views/mine/set")
        },
        {
            path: "/gallery/daguanli",
            meta: {
                title: "档案管理",
            },
            component: () =>
                import ("@/views/gallery/daguanli")
        },
        {
            path: "/jumpdetails/detaila",
            meta: {
                title: "在青就业高校毕业生住房补贴",
            },
            component: () =>
                import ("@/views/Jumpdetails/detaila")
        },
        {
            path: "/jumpdetails/detailb",
            meta: {
                title: "青年人才在青创新创业一次性安家费",
            },
            component: () =>
                import ("@/views/Jumpdetails/detailb")
        },
        {
            path: "/jumpdetails/detailc",
            meta: {
                title: "高校毕业生小微企业就业补贴",
            },
            component: () =>
                import ("@/views/Jumpdetails/detailc")
        },
        {
            path: "/jumpdetails/detaild",
            meta: {
                title: "社保补贴和岗位补贴",
            },
            component: () =>
                import ("@/views/Jumpdetails/detaild")
        },
        {
            path: "/jumpdetails/detaile",
            meta: {
                title: "灵活就业社会保险补贴",
            },
            component: () =>
                import ("@/views/Jumpdetails/detaile")
        },
        {
            path: "/jumpdetails/detailf",
            meta: {
                title: "“三支一扶”计划",
            },
            component: () =>
                import ("@/views/Jumpdetails/detailf")
        },
        {
            path: "/jumpdetails/detailg",
            meta: {
                title: "大学生基层公共管理和社会服务岗位",
            },
            component: () =>
                import ("@/views/Jumpdetails/detailg")
        },
        {
            path: "/jumpdetails/detailh",
            meta: {
                title: "青年就业见习补贴",
            },
            component: () =>
                import ("@/views/Jumpdetails/detailh")
        },
        {
            path: "/jumpdetails/detaili",
            meta: {
                title: "在青高校在校大学生就业能力提升补贴",
            },
            component: () =>
                import ("@/views/Jumpdetails/detaili")
        },
        {
            path: "/jumpdetails/detailj",
            meta: {
                title: "大学生综合职业能力培训补贴",
            },
            component: () =>
                import ("@/views/Jumpdetails/detailj")
        },
        {
            path: "/jumpdetails/detailk",
            meta: {
                title: "一次性创业补贴",
            },
            component: () =>
                import ("@/views/Jumpdetails/detailk")
        },
        {
            path: "/jumpdetails/detaill",
            meta: {
                title: "一次性小微企业创业补贴",
            },
            component: () =>
                import ("@/views/Jumpdetails/detaill")
        },
        {
            path: "/jumpdetails/detailm",
            meta: {
                title: "一次性创业岗位开发补贴",
            },
            component: () =>
                import ("@/views/Jumpdetails/detailm")
        },
        {
            path: "/jumpdetails/detailn",
            meta: {
                title: "创业担保贷款",
            },
            component: () =>
                import ("@/views/Jumpdetails/detailn")
        },
        {
            path: "/jumpdetails/detailo",
            meta: {
                title: "小微企业创业担保贷款",
            },
            component: () =>
                import ("@/views/Jumpdetails/detailo")
        },
        {
            path: "/jumpdetails/detailp",
            meta: {
                title: "小微企业创业担保贷款",
            },
            component: () =>
                import ("@/views/Jumpdetails/detailp")
        },
        {
            path: "/gallery/hkguanli",
            meta: {
                title: "户口管理",
            },
            component: () =>
                import ("@/views/gallery/hkguanli")
        },
        {
            path: "/gallery/hkguanli-style",
            meta: {
                title: "落户方式",
            },
            component: () =>
                import ("@/views/gallery/hkguanli-style")
        },
        {
            path: "/gallery/hkguanli-item",
            meta: {
                title: "户口用途",
            },
            component: () =>
                import ("@/views/gallery/hkguanli-item")
        },
        {
            path: "/gallery/hkguanli-gfprove",
            meta: {
                title: "购房证明",
            },
            component: () =>
                import ("@/views/gallery/hkguanli-gfprove")
        },
        {
            path: "/gallery/hkguanli-jzz",
            meta: {
                title: "居住证",
            },
            component: () =>
                import ("@/views/gallery/hkguanli-jzz")
        },
        {
            path: "/calculator/incomeTax",
            meta: {
                title: "工资计算器",
                keepAlive: true
            },
            component: () =>
                import ('@/views/calculator/incomeTax/index')
        },
        {
            path: "/calculator/incomeTax/month",
            meta: {
                title: "工资月度计算",
                keepAlive: true
            },
            component: () =>
                import ('@/views/calculator/incomeTax/month')
        },
        {
            path: "/calculator/incomeTax/year",
            meta: {
                title: "工资年度计算",
                keepAlive: true
            },
            component: () =>
                import ('@/views/calculator/incomeTax/year')
        },
        {
            path: "/calculator/incomeTax/incomeDetail",
            meta: {
                title: "收入详情",
                keepAlive: true
            },
            component: () =>
                import ('@/views/calculator/incomeTax/incomeDetail')
        },
        {
            path: "/calculator/incomeTax/fund",
            meta: {
                title: "公积金调整",
                keepAlive: true
            },
            component: () =>
                import ('@/views/calculator/incomeTax/fund')
        },
        {
            path: "/calculator/incomeTax/socialIns",
            meta: {
                title: "社保调整",
                keepAlive: true
            },
            component: () =>
                import ('@/views/calculator/incomeTax/socialIns')
        },
        {
            path: "/calculator/incomeTax/notes",
            meta: {
                title: "分月收入记录",
                keepAlive: true
            },
            component: () =>
                import ('@/views/calculator/incomeTax/notes')
        },
        {
            path: "/calculator/security",
            meta: {
                title: "社保计算",
                keepAlive: true
            },
            component: () =>
                import ('@/views/calculator/security/index')
        },
        {
            path: "/calculator/security/workers",
            meta: {
                title: "职工社保计算",
                keepAlive: true
            },
            component: () =>
                import ('@/views/calculator/security/workers')
        },
        {
            path: "/calculator/security/flexible",
            meta: {
                title: "灵活就业社保计算",
                keepAlive: true
            },
            component: () =>
                import ('@/views/calculator/security/other')
        },
        {
            path: "/calculator/security/resident",
            meta: {
                title: "居民社保计算",
                keepAlive: true
            },
            component: () =>
                import ('@/views/calculator/security/other')
        },
        {
            path: "/calculator/fund",
            meta: {
                title: "公积金计算",
                keepAlive: true
            },
            component: () =>
                import ('@/views/calculator/fund/index')
        },
        {
            path: "/calculator/tax",
            meta: {
                title: "个税",
                keepAlive: true
            },
            component: () =>
                import ('@/views/calculator/other/tax')
        },
        {
            path: "/calculator/yearEnd",
            meta: {
                title: "年终奖",
                keepAlive: true
            },
            component: () =>
                import ('@/views/calculator/other/yearEnd')
        },
        {
            path: "/calculator/laobor",
            meta: {
                title: "劳务所得",
                keepAlive: true
            },
            component: () =>
                import ('@/views/calculator/other/laobor')
        },
        {
            path: "/calculator/special",
            meta: {
                title: "专项扣除",
                keepAlive: true
            },
            component: () =>
                import ('@/views/calculator/other/special')
        },
        {
            path: "/calculator/special/children",
            meta: {
                title: "子女教育",
                keepAlive: true
            },
            component: () =>
                import ('@/views/calculator/other/special/children')
        },
        {
            path: "/calculator/special/continue",
            meta: {
                title: "继续教育",
                keepAlive: true
            },
            component: () =>
                import ('@/views/calculator/other/special/continue')
        },
        {
            path: "/calculator/special/illness",
            meta: {
                title: "大病医疗",
                keepAlive: true
            },
            component: () =>
                import ('@/views/calculator/other/special/illness')
        },
        {
            path: "/calculator/special/rent",
            meta: {
                title: "住房租金",
                keepAlive: true
            },
            component: () =>
                import ('@/views/calculator/other/special/rent')
        },
        {
            path: "/calculator/special/old",
            meta: {
                title: "赡养老人",
                keepAlive: true
            },
            component: () =>
                import ('@/views/calculator/other/special/old')
        },
        {
            path: "/calculator/special/loan",
            meta: {
                title: "住房贷款利息",
                keepAlive: true
            },
            component: () =>
                import ('@/views/calculator/other/special/loan')
        },
        {
            path: "/search/eduction",
            name: "Eduction",
            meta: {
                title: "学历查询"
            },
            component: () =>
                import ('@/views/index/eduction')
        }
    ]
}]