/**
 * 咨询弹出层
 */
import Vue from 'vue'
import Consule from './consult'

Consule.installConsule = function(options) {
  if (options === undefined || options === null) {
    options = {
      options: ''
    }
  } else if (typeof options === 'string' || typeof options === 'number') {
    options = {
      describe: options
    }
  } else {
    options = {
      options: options
    }
  }
  const newConsule = Vue.extend(Consule)
  const component = new newConsule({
    data: options
  }).$mount()
  document.getElementById('app-main').getElementsByTagName('div')[0].appendChild(component.$el)
}

export default Consule