<template>
    <transition name="fade">
        <div class="alert-main" v-show="show">
            <!-- <span class="iconfont iconroundcheck success" v-if="options.type=='success'"></span>
            <span class="iconfont info iconinfo" v-else-if="options.type=='info'"></span>
            <span class="iconfont warning iconwarn" v-else-if="options.type=='warning'"></span>
            <span class="iconfont error iconroundclose" v-else-if="options.type=='error'"></span> -->
            <span class="text">{{options}}</span>
        </div>
    </transition>
</template>

<script type="text/ecmascript-6">
export default {
    name: 'alert',
    data() {
        return {
            options: "",
            show: true
        };
    },
    mounted() {
        setTimeout(() => {
            this.show = false
        }, 2000);
    }
}
</script>

<style lang="scss" scoped>
.fade-enter-active {
    animation: fade-in 0.3s ease-out;
}
.fade-leave-active {
    animation: fade-in 0.3s reverse ease-out;
}
@keyframes fade-in {
    0% {
        top: -40px;
    }
    100% {
        top: 100px;
    }
}
.alert-main {
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    top: 180px;
    z-index: 2121;
    span {
        display: inline-block;
        padding: 20px 40px;
        background: rgba(0, 0, 0, 0.5);
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
        color: #fff;
        font-size: 26px;
        border-radius: 32px;
        line-height: 24px;
    }
}
.alert-main .iconfont {
    margin-right: 5px;
    font-size: 20px;
    vertical-align: middle;
}
.alert-main .success {
    color: #29d3ce;
}
.alert-main .info {
    color: #2d8cf0;
}
.alert-main .warning {
    color: #f90;
}
.alert-main .error {
    color: #ed4014;
}
</style>

